<template>
  <div>
    <login-modal v-show="showLoginModal" @close="$store.setShowLoginModal(false)" />
    <settings-box v-if="user && user.authId" v-show="settingsBoxOpen" />
    <boost-modal v-show="showBoostModal" @close="showBoostModal = false" />
    <div class="fixed w-screen bg-black text-white top-0 left-0 flex justify-center text-center z-50">
      <p class="text-sm px-16 py-2">
        สนใจสร้างแบรนด์ของคุณให้เป็นที่รู้จักมากขึ้น ดูไอเดีย collab กับเราได้<a
          href="https://drinksonme.notion.site/37d5d9acab1b4f4ab167f5bd20c68b70" target="_blank"
          class="underline font-semibold">ที่นี่เลย</a>
      </p>
    </div>
    <img data-testid="home-icon-top-left" src="/nav/nav-home.png"
      class="fixed left-5 top-16 lg:top12 w-12 z-50 cursor-pointer" @click="homeButtonClicked">
    <dom-button v-if="counter" data-testid="exit-table-btn" class="fixed left-20 top-16 lg:top12 z-50 p-3 w-32"
      @click.native="leaveButtonClicked">
      <p class="font-semibold">
        ออกจากโต๊ะ
      </p>
    </dom-button>
    <confirm-home-modal v-if="showConfirmHomeModal" @close="showConfirmHomeModal = false" @confirm="leave" />
    <div class="fixed right-5 top-16 lg:top12 z-50 cursor-pointer flex gap-x-1">
      <div data-testid="boost-icon" class="relative">
        <img class="w-12 h-12 cursor-pointer z-40" src="/boost/boost-logo.png" @mouseenter="showBoostTooltip = true"
          @mouseleave="showBoostTooltip = false" @click="showBoostModal = true">
        <tooltip v-show="showBoostTooltip" class="w-36 text-sm">
          กดเพื่อดูรายละเอียดการ Boost !
        </tooltip>
      </div>
      <img v-if="user && user.authId" data-testid="sound-setting" class="w-14 cursor-pointer z-40 relative -top-1"
        src="/counter-page/settings.png" @click="toggleSoundBox">
      <div v-if="user && user.authId">
        <button data-testid="user-menu" class="w-12" @click="isMenuExpanded = !isMenuExpanded"
          @blur="isMenuExpanded = false">
          <img src="/nav/nav-menu.png">
        </button>
        <ul v-show="isMenuExpanded"
          class="absolute w-48 right-3 top-12 mb-4 bg-white divide-y dom-border overflow-hidden text-center">
          <li class="px-3 py-2 hover:bg-gray-200" @mousedown.prevent="goToBoost">
            Boost
          </li>
          <li class="px-3 py-2 hover:bg-gray-200" @mousedown.prevent="signOut">
            Log out
          </li>
        </ul>
      </div>
      <div v-else-if="!counter">
        <button data-testid="log-in-top-right" class="w-28" @click="$store.setShowLoginModal(true)">
          <img src="/nav/log-in.png">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsBox from '~/components/SettingsBox.vue'
import global from '~/mixins/global.js'
import LoginModal from '~/components/LoginModal.vue'
import ConfirmHomeModal from '~/components/ConfirmHomeModal.vue'
import Tooltip from '~/components/common/Tooltip.vue'
import BoostModal from '~/components/boost/BoostModal.vue'
import DomButton from '~/components/DomButton.vue'

export default {
  components: {
    SettingsBox,
    LoginModal,
    ConfirmHomeModal,
    Tooltip,
    BoostModal,
    DomButton,
  },
  mixins: [global],
  data () {
    return {
      isMenuExpanded: false,
      settingsBoxOpen: false,
      showConfirmHomeModal: false,
      showBoostTooltip: false,
      showBoostModal: false,
    }
  },

  computed: {
    counter () {
      return this.$store.counter
    },
  },
  methods: {
    async leave () {
      this.showConfirmHomeModal = false
      if (this.counter) {
        await $fetch('/api/counters/leave', {
          method: 'POST',
          baseURL: this.$config.public.appBaseURL,
          headers: this.headers,
        })
      }

      this.$store.setCounterEnv({ counter: null, sponsors: [] })

      this.$router.push('/app')
    },
    async signOut () {
      try {
        this.$store.setPageLoading(true)
        await $fetch('/api/disconnect', {
          method: 'POST',
          baseURL: this.$config.public.appBaseURL,
          headers: this.headers,
        })
        this.$store.setCounterEnv({ counter: null, sponsors: [] })
        await this.$supabase.auth.signOut()
      }
      catch (error) {
        this.$notify({
          type: 'info',
          text: 'กรุณาลองออกจากระบบอีกครั้ง',
        })
        this.$store.setPageLoading(false)
      }
    },
    toggleSoundBox () {
      this.settingsBoxOpen = !this.settingsBoxOpen
    },
    homeButtonClicked () {
      if (this.counter && this.$route.path === '/app') {
        this.showConfirmHomeModal = true
      } else {
        this.$router.push('/app')
      }
    },
    leaveButtonClicked () {
      this.showConfirmHomeModal = true
    },
    goToBoost () {
      window.location = '/boost'
      this.isMenuExpanded = false
    },
  },
}
</script>

<template>
  <div :id="playerId" aria-label="YouTube video player"></div>
</template>

<script setup>
import { ref, watch, computed, onMounted, onUnmounted, getCurrentInstance } from 'vue'
import debounce from 'lodash/debounce.js'

const { proxy } = getCurrentInstance()

const VOLUME_DEBOUNCE_DELAY = 300 // ms

const playerId = ref(`youtube-player-${Math.random().toString(36).substring(7)}`)
const player = ref(null)
const isYouTubeApiLoaded = ref(false)

const musicVolumn = computed(() => {
  return proxy.$store.volume.music
})

const shouldPlay = defineModel('shouldPlay')

const soundMuted = computed(() => {
  return proxy.$store.volume.isMuted
})

watch(() => soundMuted.value, (newMuted) => {
  if (player.value) {
    newMuted ? muteAndPauseVideo() : unmuteAndResumeVideo()
  }
})

watch(() => shouldPlay.value, (newShouldPlay) => {
  if (newShouldPlay) {
    startVideo()
  } else {
    stopVideo()
  }
})

const debouncedSetVolume = debounce((vol) => {
  setVolume(vol)
  if (player.value) {
    vol === 0 ? player.value.pauseVideo() : player.value.playVideo()
  }
}, VOLUME_DEBOUNCE_DELAY)

watch(() => musicVolumn.value, debouncedSetVolume)

onMounted(() => {
  startVideo()
})

onUnmounted(() => {
  stopVideo()
})

function startVideo () {
  loadYouTubeAPI()
  window.addEventListener('blur', keepPlaying)
  document.addEventListener('visibilitychange', keepPlaying)
}

function stopVideo () {
  closeVideo()
  debouncedSetVolume.cancel()
  window.removeEventListener('blur', keepPlaying)
  document.removeEventListener('visibilitychange', keepPlaying)
}

function keepPlaying() {
  if (player.value && !soundMuted.value) {
    player.value.playVideo()
  }
}

function unmuteAndResumeVideo() {
  if (player.value) {
    if (player.value.unMute) {
      player.value.unMute()
    }
    if (player.value.playVideo) {
      player.value.playVideo()
    }
  }
}

function loadYouTubeAPI() {
  if (!window.YT) {
    const tag = document.createElement('script')
    tag.src = 'https://www.youtube.com/iframe_api'
    tag.onerror = () => {
      console.error('Failed to load YouTube API')
      emit('playerError', 'Failed to load YouTube API')
    }
    if (document.head) {
      document.head.appendChild(tag)
    } else {
      console.error('Unable to load YouTube API: document.head is not available')
      return
    }

    window.onYouTubeIframeAPIReady = () => {
      isYouTubeApiLoaded.value = true
      initializePlayer()
    }
  } else {
    isYouTubeApiLoaded.value = true
    initializePlayer()
  }
}

function initializePlayer() {
  if (!isYouTubeApiLoaded.value) {
    console.error('Attempted to initialize player before YouTube API was loaded')
    return
  }

  if (window.YT?.Player) {
    const playerElement = document.getElementById(playerId.value)
    if (!playerElement) {
      console.error('Player element not found')
      return
    }

    player.value = new window.YT.Player(playerId.value, {
      height: '0',
      width: '0',
      playerVars: {
        autoplay: 0,
        controls: 0,
        disablekb: 1,
        enablejsapi: 1,
        fs: 0,
        iv_load_policy: 3,
        loop: 1,
        modestbranding: 1,
        playsinline: 1,
        rel: 0,
        showinfo: 0,
        listType: 'playlist',
        list: 'PLsnJOHsVvrjhQZdOiR0oIsGgilIAAFzEo', // ลิงก์รวมของเพลงทั้งหมด
        index: Math.floor(Math.random() * 12), // สุ่มเลือกเพลงจากรวมของเพลงทั้งหมด
        shuffle: 1,
      },
      events: {
        'onReady': onPlayerReady,
        'onError': onPlayerError
      }
    })
  } else {
    console.error('YouTube Iframe API is not loaded.')
  }
}



function onPlayerReady() {
  setVolume(musicVolumn.value)
  if (soundMuted.value || !shouldPlay.value) {
    muteAndPauseVideo()
  } else if (player.value && player.value.playVideo) {
    player.value.playVideo()
  }
}

function onPlayerError(event) {
  console.error('YouTube player error:', event.data)
}
/**
 * @param {number} vol - The volume level (0-100)
 */
function setVolume(vol) {
  if (player.value?.setVolume) {
    player.value.setVolume(Math.max(0, Math.min(100, vol)))
  }
}

function muteAndPauseVideo() {
  if (player.value) {
    if (player.value.mute) {
      player.value.mute()
    }
    if (player.value.pauseVideo) {
      player.value.pauseVideo()
    }
  }
}

function closeVideo() {
  if (player.value) {
    player.value.destroy()
    player.value = null
  }
}
</script>